var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DgCover contentModule",class:_vm.elmClasses,style:({ backgroundColor : this.bgColor }),attrs:{"data-dev-name":'DgCover'}},[_c('div',{staticClass:"DgCover__inner contentModule__inner"},[_c('div',{staticClass:"DgCover__screen DgCover__screen--first"},[_c('div',{staticClass:"DgCover__row DgCover__row--title"},[_c('div',{staticClass:"DgCover__cell DgCover__cell--titleTypoSvg"},[_c('div',{staticClass:"DgCover__box--titleTypoSvg-landscape",style:({
							backgroundImage : _vm.titleTypoSvgLandscape ? 'url('+ _vm.titleTypoSvgLandscape +')' : 'none'
						})}),_c('div',{staticClass:"DgCover__box--titleTypoSvg-portrait",style:({
							backgroundImage : _vm.titleTypoSvgPortrait ? 'url('+ _vm.titleTypoSvgPortrait +')' : 'none'
						})})])]),_vm._m(0),_c('div',{staticClass:"DgCover__row DgCover__row--text"},[_vm._m(1),_c('div',{staticClass:"DgCover__cell DgCover__cell--infoText",domProps:{"innerHTML":_vm._s(_vm.infoText)}})])]),_c('div',{staticClass:"DgCover__screen DgCover__screen--second"},[_c('div',{staticClass:"DgCover__row DgCover__row--teaserImage"},[_c('MhImage',{staticClass:"DgCover__cell DgCover__cell--teaserImage shadow shadow--coverImage",style:({
						height : _vm.teaserImageVh + 'vh',
						width : _vm.teaserImageVw + 'vw',
					}),attrs:{"imageObject":_vm.teaserImage,"mode":'contain'}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DgCover__row DgCover__row--spacer"},[_c('div',{staticClass:"DgCover__cell DgCover__cell--spacer"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DgCover__cell DgCover__cell--logo"},[_vm._v(" I I I I"),_c('br'),_vm._v(" Kunst- und Kulturstiftung"),_c('br'),_vm._v(" Opelvillen Rüsselsheim"),_c('br')])}]

export { render, staticRenderFns }