<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgImages></DgImages>

	2021-09-24	init

-->

<template>
	<div class="DgImages contentModule" :class="elmClasses" :data-dev-name="'DgImages • layout=' + layout" v-if="images.length">
		<div class="DgImages__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgImages__content contentModule__content" :class="contentElmClasses">
				<!--
					<pre name="$attrs" maxheight>{{$attrs}}</pre>
					<br/>
					<pre name="$props" maxheight>{{$props}}</pre>
					<pre name="slideRatio" maxheight>{{slideRatio}}</pre>
				-->

				<template v-if="layout === 'carousel'">
					<VueperSlides class="no-shadow"
						ref="myVueperSlides"
						:transitionSpeed="750"
						:duration="3000"
						:dragging-distance="50"
						:infinite="false"
						:touchable="true"
						:pauseOnHover="false"
						:slideRatio="slideRatio"
						:bullets="false"
						:fixedHeight="true"
						:bulletsOutside="true"
						:disableArrowsOnEdges="true"
						:gap="5"
						@ready="onSliderReady"
						@slide="onSlide">

						<template v-slot:arrow-left>
							<DgButton
								:type="'arrow-left'"
								:isCircle="true"
							></DgButton>
						</template>
						<template v-slot:arrow-right>
							<DgButton
								:type="'arrow-right'"
								:isCircle="true"
							></DgButton>
						</template>

						<VueperSlide v-for="(slide, index) in images" :key="index">
							<template v-slot:content>
								<div class="DgImages__slide">
									<template v-if="!isMediaExpired( slide )">
										<MhImage class="DgImages__slideImage" :imageObject="slide.image" :mode="'contain'"></MhImage>
									</template>
									<template v-else>
										<DgMediaExpired class="DgImages__slideImage"
											:type="'image'"
										></DgMediaExpired>
									</template>
								</div>
							</template>
						</VueperSlide>
					</VueperSlides>
					<div class="DgImages__bullets">
						<div class="DgImages__bullet" v-for="(slide, index) in images" :key="'bullet'+index"
							:class="index === currentActiveIndex ? 'DgImages__bullet--isActive' : 'DgImages__bullet--isInactive'"
							@click="$refs.myVueperSlides.goToSlide(index)">
							{{index}}
						</div>
					</div>
					<div class="DgImages__bus">
						<DgBu
							class="DgImages__bu"
							:class="index === currentActiveIndex ? 'DgImages__bu--isVisible' : 'DgImages__bu--isHidden'"
							v-for="(slide, index) in images" :key="'bu'+index"
							:caption="slide.text"
							:copyright="slide.copyright"
						></DgBu>
						<!--
						<div class="DgImages__bu fontStyle fontStyle--s"
							:class="index === currentActiveIndex || index === index ? 'DgImages__bu--isVisible' : 'DgImages__bu--isHidden'"
							v-for="(slide, index) in images" :key="'bu'+index">
							<span v-html="slide.text"></span>
							<span style="background-color: yellow" v-html="slide.copyright"></span>
						</div>
						-->
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import { VueperSlides, VueperSlide } from 'vueperslides'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import DgButton from '@/components/molecules/DgButton.vue'
	import DgMediaExpired from '@/components/molecules/DgMediaExpired.vue'
	import DgBu from '@/components/molecules/DbBu.vue'
	import formatDateToYYYYMMDD from '@/helper/formatDateToYYYYMMDD'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'
	import 'ken-burns-carousel'

	export default {
		name: 'DgImages',
		components: {
			MhImage,
			DgButton,
			VueperSlides,
			VueperSlide,
			DgBu,
			DgMediaExpired,
		},
		mixins: [ dgContentModuleMixin ],
		props: {
			images: {
				type     : [Array],
				default  : ()=>{ return []},
				required : false,
			},
			layout: {
				type     : [String, Boolean],
				default  : 'carousel',
				required : false,
			},
			/*
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
			isFullspan: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			*/
		},
		data(){
			return {
				currentActiveIndex : 0
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--textPosition-' + this.textPosition)
				if( this.isFullspan ) classes.push( this.$options.name + '--isFullspan' )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				//classes.push( 'fontStyle--size-' + this.fontSize )

				return classes
			},
			slideRatio(){
				const firstImage = this._.get( this.images, '[0].image' )
				const width = this._.get( firstImage, 'width' )
				const height = this._.get( firstImage, 'height' )
				const ratio = width && height ? height / width : 9/16

				return ratio
			},
		},
		methods: {
			onSliderReady( e, doLog = true ){},
			onSlide( e, doLog = true ){
				const index = e.currentSlide.index

				this.currentActiveIndex = index
			},
			isMediaExpired( slide ){
				let isExpired = false
				const expirationDateInt = slide.expirationDate ? parseInt( slide.expirationDate ) : undefined
				const todayDateInt = parseInt( formatDateToYYYYMMDD( undefined, '' ) )

				if( expirationDateInt && expirationDateInt <= todayDateInt ) isExpired = true

				/*
				console.log('expirationDateInt:', expirationDateInt)
				console.log('todayDateInt:', todayDateInt)
				*/

				return isExpired
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";
	@import "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhVueperSlides/v1/vueperslides.custom.less";

	.DgImages { // debug
		[showBorders2] & {}
		background-color: fade( yellow, 75 );
		//.DgImages__bus {}
		//.DgImages__bu { outline: 1px solid red; }
	}
	.DgImages { // layout
		/*
		&__inner {
			.grid;
			grid-gap: var(--app-gridGap);
		}

		.DgGrid--cols-1 &__inner { .grid--cols-12; }
		.DgGrid--cols-2 &__inner { .grid--cols-6; }
		*/

		// grid
		.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		// hSpace
		/*
		.DgGrid--cols-1 & {
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			padding-left: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			padding-right: var(--app-content-hPadding);
		}
		*/

		// maxWidth
		/*
		.DgGrid--cols-1 & {
			width: 100%;
			max-width: var(--app-content-maxWidth);
			align-self: center;
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-end;
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-start;
		}
		*/

		// isFullspan
		/*
		.DgGrid--cols-1 &--isFullspan&--textPosition-below {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__content {
			grid-column: 1 / span 12;
			display: flex;
			flex-direction: column;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__text {
			display: grid;
			.grid--cols-12;
			grid-gap: var(--app-gridGap);
			//padding-left: calc( (100%/2) - (var(--app-gridGap)*0) );
			//width: 100%;
			max-width: var(--app-content-maxWidth);
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
			align-self: center;
		}
		.DgGrid--cols-1 &--isFullspan&--textPosition-below &__textInner {
			grid-column: 3 / span 8;
		}
		*/

		// height
		.vueperslides {
			height: 80vh;
		}

		// slide
		&__slide {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			pointer-events: none;
		}
		&__slideImage {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}

		// bullets
		&__bullets {
			//background-color: fade( green, 20 );
			display: flex;
			justify-content: center;
		}

		// bus
		&__bus {
			//background-color: fade( blue, 20 );
		}

		@media @mq[md] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }

			.vueperslides {
				height: 50vh;
			}
		}
	}
	.DgImages { // styling
		// arrows
		.vueperslides__arrows {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			overflow: hidden;
			pointer-events: none;

			button {
				//position: relative;
				appearance: none;
				background-color: transparent;
				border: 0; outline: 0;
				font-size: inherit;
				line-height: inherit;
				font-family: inherit;
				z-index: 2;
			}
			button > * {
				pointer-events: auto;
			}
		}
		.vueperslides__arrow {
			transition: all 0.25s ease;
			position: absolute;
			top: 0; bottom: 0;
			//transform: translateY(0%);
			padding: 0.5em;
			color: white;
			opacity: 1;

			&--prev { left: 0; transform: translateX(-100%); font-size: 1.5em; }
			&--next { right: 0; transform: translateX(100%) }
		}
		&:hover .vueperslides__arrow {
			&--prev { transform: translateX(0%) }
			&--next { transform: translateX(0%) }
		}

		// bullets
		&__bullet {
			height: 0.65em; width: 0.65em;
			overflow: hidden;
			background-color: currentColor;
			border-radius: 50%;
			margin: 1em 0.25em;
			cursor: pointer;
		}
		&__bullet--isActive {
			opacity: 0.75;
		}
		&__bullet--isInactive {
			opacity: 0.2;
		}

		// bu
		&__bu {
			//padding-top: 0.75em;
		}
		&__bu--isVisible {
			display: block;
		}
		&__bu--isHidden {
			display: none;
		}

		@media @mq[md] {
			// bullets
			&__bullet {
				height: 0.5em; width: 0.5em;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
