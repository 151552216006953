<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgLayout></DgLayout>

	2021-09-24	init

-->

<template>
	<div class="DgLayout" :class="elmClasses">
		<div class="DgLayout__inner">

			<!--
			<pre name="post.type">{{post.type}}</pre>
			<pre name="post.acf" maxheight>{{post.acf}}</pre>
			-->

			<!-- DgCover if guide -->
			<DgSection v-if="!isArticlePost">
				<DgGrid>
					<DgColumn>
						<DgCover
							:bgColor="_.get( post, 'acf.cover.bgColor' )"
							:titleTypoSvgLandscape="_.get( post, 'acf.cover.titleTypoSvg--landscape' )"
							:titleTypoSvgPortrait="_.get( post, 'acf.cover.titleTypoSvg--portrait' )"
							:infoText="_.get( post, 'acf.cover.infoText' )"
							:teaserImage="_.get( post, 'acf.cover.teaserImage' )"
							:teaserImageVh="_.get( post, 'acf.cover.teaserImageVh' )"
							:teaserImageVw="_.get( post, 'acf.cover.teaserImageVw' )"
						></DgCover>
					</DgColumn>
				</DgGrid>
			</DgSection>

			<!-- when article: start with DgArticleHeader -->
			<DgSection v-if="isArticlePost">
				<DgGrid>
					<DgColumn>
						<DgArticleHeader
							:overTitle="_.get( post, 'acf.overTitle' )"
							:title="_.get( post, 'acf.title' )"
							:style="{ backgroundColor : _.get( post, 'acf.bgColor', '' ) }"
						></DgArticleHeader>
					</DgColumn>
				</DgGrid>
			</DgSection>

			<!-- all the sections -->
			<DgSection
				v-for="(section, i) in _.get( post, 'acf.sections' )" :key="'serif'+i"
				:index="i"
				:isAnchor="section.isAnchor"
				:feature="section.feature"
				:hasArticles="section.hasArticles"
				:articles="section.articles"
				:partPostId="section.partPostId"
				:bgColor="section.bgColor"
				:textColor="section.textColor"
				:isMinHeight100vh="section.isMinHeight100vh">

				<DgGrid v-for="(grid, j) in _.get( section, 'grids' )" :key="'gr'+j"
					:class="{
						'DgGrid--isFirst' : j === 0,
						'DgGrid--isLast' : j === _.get( section, 'grids' ).length-1
					}"
					:countColums="grid.countColums">

					<DgColumn v-for="(column, k) in _.get( grid, 'columns' )" :key="'cl'+k"
						:class="{
							'DgColumn--isFirst' : k === 0,
							'DgColumn--isLast' : k === _.get( grid, 'columns' ).length-1
						}"
						:index="k">
						<!--
						<pre class="GuideView__pre" name="column">{{column}}</pre>
						-->

						<!-- walk the contentModules -->
						<template v-for="(contentModule, l) in column" >
							<!-- special: stickyWrapper with slot with contentModules -->
							<template v-if="'DgStickyWrapper' === getComponentName( contentModule )">
								<DgStickyWrapper :key="'cm'+l" v-bind="getComponentBind( contentModule )">
									<div v-for="(innerContentModule, l) in contentModule.contentModules" :key="'cm'+l"
										:is="getComponentName( innerContentModule )"
										:style="{ backgroundColor : isArticlePost ? _.get( post, 'acf.bgColor', '' ) : '' }"
										v-bind="getComponentBind( innerContentModule )"
									></div>
								</DgStickyWrapper>
							</template>
							<!-- default: output the contentModules -->
							<div v-else :key="'cm'+l"
								:is="getComponentName( contentModule )"
								:style="{ backgroundColor : isArticlePost ? _.get( post, 'acf.bgColor', '' ) : '' }"
								v-bind="getComponentBind( contentModule )"
							></div>
						</template>
					</DgColumn>

				</DgGrid>

			</DgSection>

			<!-- just a spacer if article -->
			<DgSection v-if="isArticlePost">
				<DgGrid>
					<DgColumn>
						<DgSpacer
							:size="'half'"
							:style="{ backgroundColor : _.get( post, 'acf.bgColor', '' ) }"
						></DgSpacer>
					</DgColumn>
				</DgGrid>
			</DgSection>

		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	import DgSection from '@/components/organisms/DgSection.vue'
	import DgGrid from '@/components/organisms/DgGrid.vue'
	import DgColumn from '@/components/organisms/DgColumn.vue'

	import DgCover from '@/components/contentModules/DgCover.vue'
	import DgComponentNotFound from '@/components/contentModules/DgComponentNotFound.contentModule.vue'
	import DgText from '@/components/contentModules/DgText.contentModule.vue'
	import DgQuote from '@/components/contentModules/DgQuote.contentModule.vue'
	import DgChapterHeader from '@/components/contentModules/DgChapterHeader.contentModule.vue'
	import DgHeadline from '@/components/contentModules/DgHeadline.contentModule.vue'
	import DgImage from '@/components/contentModules/DgImage.contentModule.vue'
	import DgSpacer from '@/components/contentModules/DgSpacer.contentModule.vue'
	import DgArticleHeader from '@/components/contentModules/DgArticleHeader.contentModule.vue'
	import DgImages from '@/components/contentModules/DgImages.contentModule.vue'
	import DgStickyWrapper from '@/components/contentModules/DgStickyWrapper.contentModule.vue'
	import DgVideoPlayer from '@/components/contentModules/DgVideoPlayer.contentModule.vue'
	import DgAudioPlayer from '@/components/contentModules/DgAudioPlayer.contentModule.vue'
	import DgLogoRow from '@/components/contentModules/DgLogoRow.contentModule.vue'

	export default {
		name: 'DgLayout',
		components: {
			DgSection,
			DgGrid,
			DgColumn,
			DgComponentNotFound,
			DgCover,
			DgText,
			DgQuote,
			DgChapterHeader,
			DgHeadline,
			DgImage,
			DgSpacer,
			DgArticleHeader,
			DgImages,
			DgStickyWrapper,
			DgVideoPlayer,
			DgAudioPlayer,
			DgLogoRow,
		},
		mixins: [],
		props: {
			stylesheet: {
				type     : [Object],
				default  : undefined,
				required : false,
			},
			post: {
				type     : [Object],
				default  : undefined,
				required : false,
			},
		},
		data(){
			return {
				isMounted : false,
			}
		},
		watch: {
			isReady: {
				handler: function( to, from ) {
					if( to ) this.setStylesheetAsCssProperties()
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isReady ) classes.push( this.$options.name + '--isReady')
				else classes.push( this.$options.name + '--isNotReady')

				if( this.isArticlePost ) classes.push( this.$options.name + '--isArticle')
				else classes.push( this.$options.name + '--isGuide')

				return classes
			},
			isArticlePost(){
				return 'article_post' === this._.get( this.post, 'type' )
			},
			isGuidePost(){
				return 'guides_post' === this._.get( this.post, 'type' )
			},
			isReady(){
				const isReady =
					this._.size( this.post ) &&
					this._.size( this.stylesheet ) &&
					this._.get( this.$el, 'nodeType' ) === 1 &&
					this.isMounted ?
					true : false

				return isReady
			},
		},
		methods: {
			getComponentName( obj ){
				const componentName = 'Dg' + this._.upperFirst( obj.acf_fc_layout )
				const availableComponents = this.$options.components
				const hasComponent = this._.has( availableComponents, componentName )

				return hasComponent ? componentName : 'DgComponentNotFound'
			},
			getComponentBind( obj ){
				return obj
			},
			setStylesheetAsCssProperties( doLog = false ){
				const fontStylesheet = this._.get( this.stylesheet, 'fontStyles' )

				if( doLog ){
					console.group(this.$options.name + ' • setStylesheetAsCssProperties()')
					console.log('$el:', this.$el)
					console.log('stylesheet:', this.stylesheet)
					console.log('fontStylesheet:', this.fontStylesheet)
					console.log('---')
				}

				this._.forEach( fontStylesheet , (values, name)=>{
					if( doLog ){
						console.group('fontStylesheet • ' + name)
						console.log( '--fontStyle-' + name + '-fontFamily', values.fontFamily )
						console.log( '--fontStyle-' + name + '-fontWeight', values.fontWeight )
						console.log( '--fontStyle-' + name + '-dt-fontSize', values.dt.fontSize + 'px' )
						console.log( '--fontStyle-' + name + '-dt-lineHeight', values.dt.lineHeight + 'em' )
						console.log( '--fontStyle-' + name + '-md-fontSize', values.md.fontSize + 'px' )
						console.log( '--fontStyle-' + name + '-md-lineHeight', values.md.lineHeight + 'em' )
						console.log( '--fontStyle-' + name + '-sm-fontSize', values.sm.fontSize + 'px' )
						console.log( '--fontStyle-' + name + '-sm-lineHeight', values.sm.lineHeight + 'em' )
						console.groupEnd()
					}

					this.$el.style.setProperty( '--fontStyle-' + name + '-fontFamily', values.fontFamily )
					this.$el.style.setProperty( '--fontStyle-' + name + '-fontWeight', values.fontWeight )
					this.$el.style.setProperty( '--fontStyle-' + name + '-dt-fontSize', values.dt.fontSize + 'px' )
					this.$el.style.setProperty( '--fontStyle-' + name + '-dt-lineHeight', values.dt.lineHeight + 'em' )
					this.$el.style.setProperty( '--fontStyle-' + name + '-md-fontSize', values.md.fontSize + 'px' )
					this.$el.style.setProperty( '--fontStyle-' + name + '-md-lineHeight', values.md.lineHeight + 'em' )
					this.$el.style.setProperty( '--fontStyle-' + name + '-sm-fontSize', values.sm.fontSize + 'px' )
					this.$el.style.setProperty( '--fontStyle-' + name + '-sm-lineHeight', values.sm.lineHeight + 'em' )
				})

				if( doLog ){ console.groupEnd() }

			},
		},
		created(){},
		mounted(){
			this.isMounted = true

			//console.log('this:', this)
			//console.log('this.$options.components:', this.$options.components)
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgLayout { // debug
		[showBorders2] & {}
	}
	.DgLayout { // layout
		pointer-events: none; // enable click-through
	}
	.DgLayout { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
