<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgChapterHeader></DgChapterHeader>

	2021-09-24	init

-->

<template>
	<div class="DgChapterHeader contentModule" :class="elmClasses" :data-dev-name="'DgChapterHeader'">
		<div class="DgChapterHeader__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgChapterHeader__content contentModule__content" :class="contentElmClasses">
				<div class="DgChapterHeader__overTitle richText hyphenate fontStyle fontStyle--s">
					<span class="DgChapterHeader__overTitleText hideIfEmpty" v-html="overTitle"></span>
					<span class="DgChapterHeader__overTitleLine"></span>
				</div>
				<div class="DgChapterHeader__title hideIfEmpty richText hyphenate noWordWrap fontStyle fontStyle--xxl" v-html="title"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'

	export default {
		name: 'DgChapterHeader',
		components: {},
		mixins: [ dgContentModuleMixin ],
		props: {
			//imageObject: [Object, Boolean],
			//DgChapterHeader: [String],
			overTitle: {
				type     : [String],
				default  : '',
				required : false,
			},
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				//classes.push( 'font--size-' + this.fontSize )

				return classes
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){
			EventBus.$emit('doRegisterMenuItem', {
				title : this.title,
			})
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgChapterHeader { // debug
		[showBorders2] & {}
		/*
		&__overTitle { background-color: fade( red, 50 ); }
		&__title { background-color: fade( orange, 50 ); }
		*/
	}
	.DgChapterHeader { // layout
		// grid
		.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		&__overTitle {
			display: flex;
			align-items: flex-end;
		}
		&__overTitleText {
			flex-shrink: 0;
		}
		&__overTitleLine {}

		@media @mq[sm] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }
		}
	}
	.DgChapterHeader { // styling
		&__overTitleText {
			margin-right: 0.5em;
		}
		&__overTitleLine { // line after overTitle
			height: 0.3em;
			width: 100%;
			background-color: currentColor;
			transform: translateY(-0.35em);
		}
		&__title {
			margin-top: 0.26em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
