import { render, staticRenderFns } from "./DgStickyWrapper.contentModule.vue?vue&type=template&id=39fe7ee8&"
import script from "./DgStickyWrapper.contentModule.vue?vue&type=script&lang=js&"
export * from "./DgStickyWrapper.contentModule.vue?vue&type=script&lang=js&"
import style0 from "./DgStickyWrapper.contentModule.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports